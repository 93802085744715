import React from 'react';
import blockchain from "../../assets/blockchain.svg";
import immutable from "../../assets/immutable.svg";
import smart from "../../assets/smart.svg";
import reduced from "../../assets/reduced.svg";
import decentralized from "../../assets/decentralized.svg";
import crypto from "../../assets/crypto.svg";





const Key = () => {


    return (
        <section>
            <div className='bg-[#070624] '>

                <div className="container mx-auto px-4 sm:px-[80px] py-[100px]">

                    <h2 className="text-white text-3xl pb-[48px] font-bold leading-10">
                        Key Features

                    </h2>
                    <div className='bg-[#070624] grid-cols-4 gap-4 md:flex md:flex-row'>
                        <div
                            class="mx-3 mt-6 flex flex-col rounded-lg bg-[#070624] sm:shrink-0 sm:grow sm:basis-0 border-white border-2">

                            <div className="w-full h-1/2 shadow-md ">
                                <h3 className="font-bold text-white text-lg mx-4">
                                    Blockchain Integration
                                </h3>
                                <p className="font-normal items-center text-white text-sm sm:p-4 p-4">
                                    ThriftChain leverages blockchain technology
                                    <br></br>for enhanced security and transparency.
                                    <br></br>Smart contracts are used for automated savings
                                    <br></br>and contribution management, creating an
                                    <br></br>immutable ledger for transaction history and
                                    <br></br>saving activities. The use of blockchain ensures
                                    <br></br>that users can trust the platform with their
                                    <br></br>financial transactions and contributes to the
                                    <br></br>overall reliability and security of the service.


                                </p>

                            </div>
                            <img
                                src={blockchain}
                                alt="blockchain"
                                className="object-fit-object w-full mt-16 rounded"
                            />
                        </div>

                        <div
                            class="mx-3 mt-6 flex flex-col rounded-lg bg-[#070624] sm:shrink-0 sm:grow sm:basis-0 border-white border-2">

                            <div className="w-full h-1/2  shadow-md mx-2 ">
                                <h3 className="font-bold text-white text-lg mx-4">
                                    Immutable Ledger
                                </h3>
                                <p className="font-normal items-center text-white text-sm p-4 sm:p-4">
                                    ThriftChain's integration of blockchain
                                    <br />technology ensures that all transactions, savings
                                    <br /> activities, and contributions are recorded on an
                                    <br />immutableledger. This ledger is  transparent
                                    <br /> and can be audited by  users, providing
                                    <br /> a high level of trust and accountability.
                                    <br />  Users can verify their financial activities,
                                    <br /> contributing to a secure and transparent
                                    <br /> financial ecosystem.
                                </p>


                            </div>
                            <img
                                src={immutable}
                                alt="immutable"
                                className="object-fit-object w-full object-center rounded mt-24"
                            />

                        </div>
                        <div
                            class="mx-3 mt-6 flex flex-col rounded-lg bg-[#070624] sm:shrink-0 sm:grow sm:basis-0 border-white border-2">
                            <div className="w-full h-1/2 shadow-md mx-2">
                                <h3 className="font-bold text-white text-lg mx-4">
                                    Smart Contract Automation
                                </h3>
                                <p className="font-normal  items-center text-white text-sm sm:p-4 p-4">
                                    The use of smart contracts in ThriftChain
                                    <br />automates various aspects of the platform.
                                    <br />Smart contracts manage individual and
                                    <br />group-based savings, including the deduction
                                    <br /> of funds from users' wallets, tracking progress,
                                    <br />and releasing savings upon maturity. This
                                    <br />automation reduces the need for manual
                                    <br />intervention, increasing efficiency and
                                    <br /> reliability in the savings process.
                                </p>

                            </div>
                            <img
                                src={smart}
                                alt="smart"
                                className="object-fit-object h-2/3 w-full object-center rounded mt-24"
                            />
                        </div>



                    </div>
                    <div className='bg-[#070624] grid-cols-4 gap-4 md:flex md:flex-row'>
                        <div
                            class="mx-3 mt-6 flex flex-col rounded-lg bg-[#070624] sm:shrink-0 sm:grow sm:basis-0 border-white border-2">

                            <div className="w-full h-1/2 shadow-md mx-2">
                                <h3 className="font-bold text-white text-lg mx-4">
                                    Reduced Intermediaries
                                </h3>
                                <p className="font-normal  items-center text-white text-sm sm:p-4 p-4">
                                    By using blockchain technology, ThriftChain
                                    <br></br>reduces the need for traditional intermediaries
                                    <br></br>in financial transactions. This not only
                                    <br></br>streamlines the process but also minimizes
                                    <br></br>associated fees,making savings and
                                    <br></br>contributions more cost-effective for users.


                                </p>

                            </div>
                            <img
                                src={reduced}
                                alt="reduced"
                                className="object-fit-object h-2/3 w-full object-center rounded mt-36"
                            />
                        </div>

                        <div
                            class="mx-3 mt-6 flex flex-col rounded-lg bg-[#070624] sm:shrink-0 sm:grow sm:basis-0 border-white border-2">

                            <div className="w-full h-1/2 shadow-md mx-2 ">
                                <h3 className="font-bold text-white text-lg mx-4">
                                    Decentralized Verification
                                </h3>
                                <p className="font-normal items-center text-white text-sm sm:p-4 p-4">
                                    Blockchain technology operates on a
                                    <br />decentralized network of nodes, which
                                    <br />means that financial activities on ThriftChain
                                    <br />are verified and recorded across a distributed
                                    <br />network. This decentralized nature enhances
                                    <br />security, as it reduces the risk of a single
                                    <br />point of failure and minimizes the potential for
                                    <br />fraud or tampering with financial data.

                                </p>



                            </div>
                            <img
                                src={decentralized}
                                alt="decentralized"
                                className="object-fit-object h-2/3 w-full object-center rounded mt-24"
                            />
                        </div>
                        <div
                            class="mx-3 mt-6 flex flex-col rounded-lg bg-[#070624] sm:shrink-0 sm:grow sm:basis-0 border-white border-2">

                            <div className="w-full h-1/2 shadow-md mx-2  ">
                                <h3 className="font-bold text-white text-lg mx-4">
                                    Cryptocurrency Compatibility
                                </h3>
                                <p className="font-normal  items-center text-white text-sm sm:p-4 p-4">
                                    ThriftChain's blockchain integration allows users
                                    <br></br>stable coins like USDT or USDC. This 
                                    <br></br>compatibility with a variety of cryptocurrencies 
                                    <br></br>ensures flexibility and accessibility for users 
                                    <br></br>who prefer different digital assets for their 
                                    <br />savings and contributions.
                                </p>

                            </div>
                            <img
                                src={crypto}
                                alt="crypto"
                                className="object-fit-object h-2/3 w-full object-center rounded mt-36"
                            />
                        </div>

                    </div>

                </div>
            </div>
        </section>

    )


}


export default Key;




