import React from 'react';
import AllRoutes from './config/AllRoutes';




const App = () => {
  return (
  
    <div>
      <AllRoutes />
    </div>
    
  );
};

export default App;
